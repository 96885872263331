import { createTheme } from '@mui/material/styles';
import background from './data/background_right_logo.jpg'
import './OurDystopia.css'

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({color: { main: mainColor }});

export const ourDystopiaTheme = createTheme({
    palette: {
      primary: {
        main: '#131a22'
      },
      secondary: {
        main: '#66aa00'
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(255,255,255,0.4)'
      },
      background: {
        default: 'rgb(18, 18, 18)',
        paper: '#181f27'
      },
      action: {
        disabledBackground: 'rgba(255, 255, 255, 0.2)'
      },
      button: createColor('#525D7A'),
      link: createColor('#525D7A')
    },
    typography: {
      fontFamily: "Verdana, Arial, sans-serif",
      body1: {
        fontSize: "0.875rem"
      }
    },
    pageBackgroundStyle: {      
      background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(18, 18, 18, 1) 30%), url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundPositionX: "right",
      backgroundPositionY: "32px",
      backgroundSize: "contain",
      overflow: "hidden",
      padding: "0"
    }
  });