import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Link, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import IconLinks from "../constants/IconLinks";
import logo from '../data/images/m-logo.png'


const Footer = () => {
    
    const [year, setYear] = useState();
    const theme = useTheme();

    useEffect(() => setYear(new Date().getFullYear()), []);
    
    return ( 
        <Grid className="footer" container spacing={2} columns={{ xs: 4, md: 12}} mt="32px" >
            <Grid item xs={4} alignSelf="center" className="hidden-mobile" >
                <img src={ logo } className="footer-logo" />
                <Stack direction="row" spacing={1}>
                    {
                    IconLinks.map(value => (
                        <IconButton key={value.name} href={ value.link } target="_blank" size="medium" edge="start" color="inherit" aria-label={ value.name } >
                            <FontAwesomeIcon icon={value.icon} />
                        </IconButton>
                    ))}                    
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={ 0.7 }>
                    <h3>Martyrion</h3>
                    <p>Melodic Death Metal</p>
                    <p>Cologne</p>
                    <p>Germany</p>
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={ 0.7 }>
                    <h3>Links</h3>
                    {/* <Link href="/contact" underline="hover" color={ theme.palette.link.main }>Contact</Link> */}
                    <Link href="mailto:kontakt@martyrion.com" underline="hover" color={ theme.palette.link.main } >kontakt@martyrion.com</Link>
                    <Link href="/disclaimer" underline="hover" color={ theme.palette.link.main }>Disclaimer</Link>
                    <Link href="https://www.pyramid-saiten.de" target="_blank" rel="noopener" underline="hover" color={ theme.palette.link.main }>Pyramid Saiten</Link>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <p style={{textAlign: "center"}}>Copyright © 2016-{ year } Martyrion.</p>
            </Grid>
        </Grid>
     );
}
 
export default Footer;