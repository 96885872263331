import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { List, ListItemButton, ListItemIcon, ListItemText, Drawer as MuiDrawer, Toolbar, useTheme, Collapse, ListItem } from "@mui/material";
import getPageLinks from "../constants/PageLinks";

const drawerWidth = 240;

const Drawer = ({isOpen, onClosing }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const [pageLinks, setPageLinks] = useState([]);
    const [openElement, setOpenElement] = useState("");

    useEffect(() => {
        getPageLinks().then(links => setPageLinks(links));
    }, []);

    const toggleOpenElement = (elementName) => {
        if (openElement === elementName)
            setOpenElement("");
        else
            setOpenElement(elementName);
    }


    const ListItem = (itemData, padding) => {
        return (
                <ListItemButton key={ itemData.name } onClick={() => navigate(itemData.link)} sx={{ pl: padding }}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={ itemData.icon } color={ theme.palette.text.secondary }/>
                    </ListItemIcon>
                    <ListItemText primary={ itemData.name } secondary={itemData.sub} />
                </ListItemButton>
        )
    }

    const ListItemWithChildren = (itemData, padding) => {
        return (
            <div key={ itemData.name }>
                <ListItemButton onClick={() => toggleOpenElement(itemData.name) }>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={ itemData.icon } color={ theme.palette.text.secondary }/>
                    </ListItemIcon>
                    <ListItemText primary={ itemData.name } secondary={itemData.sub} />
                    { openElement === itemData.name ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
                <Collapse in={ openElement === itemData.name } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        { itemData.children.map(child => ListItem(child, padding + 4))}
                    </List>
                </Collapse>
            </div>
        )
    }

    return ( 
    <MuiDrawer open={ isOpen } onClose={ onClosing } 
        size="large" sx={{width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }}}
        variant="persistent">
        <Toolbar />
        <List component="nav">
            { pageLinks.map(link => link.children ? ListItemWithChildren(link, 4) : ListItem(link)) }
        </List>
    </MuiDrawer> 
    );
}
 
export default Drawer;

