import { useEffect, useState } from 'react';
import { Backdrop, Box, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import LoadingOverlay from '../components/LoadingOverlay'
import aboutText from '../data/about.txt'
import bandPhoto from '../data/images/band.jpg'
import bandPhotoFull from '../data/images/band_full.jpg'

const Band = ({ setPageTitle }) => {

    const [about, setAbout] = useState("");
    const [loading, setLoading] = useState(true);
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        const fetchAbout = async () => {
            const result = await fetch(aboutText);
            const resultText = await result.text();
            
            setAbout(resultText);
            setLoading(false);           
        }
        
        setPageTitle("Band");
        fetchAbout().catch(console.error);
    }, []);

    return ( 
        <div className="band">
            { loading && <LoadingOverlay />}
                        {
                !loading &&
                <>
                    <h2>Band</h2>
                    <p>
                        <img className="image-in-text click-to-open" src={ bandPhoto } onClick={ () => setBackdropOpen(true) } alt="Martyrion band photo" />
                        { about }
                    </p>
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={ backdropOpen }>
                        <Stack sx={{maxHeight: '90%'}}>
                            <Stack direction="row" mb="-48px" mr="48px">
                                <Box sx={{flexGrow: 1}} />
                                <IconButton size="large" edge="start" color="inherit" aria-label="close" onClick={ () => setBackdropOpen(false)}>
                                    <FontAwesomeIcon icon={ solid('x') } />
                                </IconButton>
                            </Stack>
                            <img src={ bandPhotoFull } className="fullscreen" alt="Martyrion band photo" />
                        </Stack>
                    </Backdrop>
                </>
            }
        </div>
     );
}
 
export default Band;