import { useEffect } from "react";

const Music = ({ setPageTitle }) => {

    useEffect(() => setPageTitle("Music"), []);

    return ( 
    <div className="music">
        <h2>Music</h2>
        <iframe className="spotify-player"
                src="https://open.spotify.com/embed/artist/2mDDiaKFruv2doyTarfN8J?utm_source=generator&theme=0" 
                loading="lazy"
                width="100%"
                height="752"
                frameBorder="0"
                allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
        </iframe>
    </div> );
}
 
export default Music;