import {useEffect, useState} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Checkbox, Container, CssBaseline, FormControlLabel, Toolbar} from '@mui/material';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ourDystopiaTheme} from './themes/our-dystopia/OurDystopiaTheme';
import {gaiaUprisingTheme} from './themes/gaia-uprising/GaiaUprisingTheme';
import './App.css';
import Drawer from './components/Drawer';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Band from './pages/Band';
import NotFound from './pages/NotFound';
import ReChapter from './pages/ReChapter';
import Disclaimer from './pages/Disclaimer';
import Music from './pages/Music';
import Downloads from './pages/Downloads';
import GaiaUprising from "./pages/promo/gaia-uprising/GaiaUprising";

function App() {

    const [theme, setTheme] = useState(ourDystopiaTheme);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState("Home");
    const [isStaging, setIsStaging] = useState(false);
    const [containerWidth, setContainerWidth] = useState('md');
    const [navbarVisible, setNavbarVisible] = useState(true);

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "DEVELOPMENT") setIsStaging(true);

    }, []);

    const setPageTitleConsolidated = (pageTitleValue) => {
        if (isStaging) setPageTitle("!!!STAGING!!! - " + pageTitleValue + " - !!!STAGING!!!"); else setPageTitle(pageTitleValue);
    }

    const handleCheckbox = (event) => {
        if (event.target.checked) setTheme(gaiaUprisingTheme); else setTheme(ourDystopiaTheme);
    }

    return (<ThemeProvider theme={theme}>
        <CssBaseline/>
        <Container maxWidth="false" style={theme.pageBackgroundStyle}>
            <div className="main-container" id="main-container">
                <Router>
                    <Navbar isDrawerOpen={isDrawerOpen}
                            onToggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
                            pageTitle={pageTitle}
                            navbarVisible={navbarVisible}/>
                    <Drawer isOpen={isDrawerOpen} onClosing={() => setIsDrawerOpen(false)} theme={theme}/>
                    <Toolbar/>
                    <Container maxWidth={containerWidth} className="content">
                        <div className="flex-box">
                            {/*{isStaging &&*/}
                            {/*    <FormControlLabel control={<Checkbox onChange={handleCheckbox}/>}*/}
                            {/*                      label="Preview Gaia Uprising Theme"/>*/}
                            {/*}*/}
                            <Routes>
                                <Route exact path="/" element={<Home setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/band" element={<Band setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/disclaimer"
                                       element={<Disclaimer setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/re/:id"
                                       element={<ReChapter setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/music" element={<Music setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/downloads"
                                       element={<Downloads setPageTitle={setPageTitleConsolidated}/>}/>
                                <Route path="/promo/gaia-uprising"
                                       element={<GaiaUprising setPageTitle={setPageTitleConsolidated}
                                                              setTheme={setTheme}
                                                              setContainerWidth={setContainerWidth}
                                                              setNavbarVisible={setNavbarVisible}/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                            <Footer className="footer"/>
                        </div>
                    </Container>
                </Router>
            </div>
        </Container>
    </ThemeProvider>);
}

export default App;
