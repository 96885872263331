import { Link, Stack, useTheme } from "@mui/material";
import { useEffect } from "react";
import PressKit from '../data/downloads/Martyrion_Presskit.zip'
import BandInfoEn from '../data/downloads/Info_EN.pdf'
import BandInfoDe from '../data/downloads/Info_DE.pdf'
import BandPhoto from '../data/downloads/Martyrion_Bandfoto.jpg'
import TechRider from '../data/downloads/Martyrion_Techrider_Censored.pdf'

const Downloads = ({ setPageTitle }) => {

    const theme = useTheme();
    useEffect(() => setPageTitle("Downloads"), []);

    return ( 
        <div className="press">
            <h3>Press downloads</h3>
            <Stack spacing={2}>
                <Link href={ PressKit } download="Martyrion PressKit" target="_blank" underline="hover" color={ theme.palette.link.main }>Press kit</Link>
                <Link href={ BandInfoEn } download="Martyrion band info English" target="_blank" underline="hover" color={ theme.palette.link.main }>Press text &#40;English&#41;</Link>
                <Link href={ BandInfoDe } download="Martyrion band info German" target="_blank" underline="hover" color={ theme.palette.link.main }>Press text &#40;German&#41;</Link>
                <Link href={ BandPhoto } download="Martyrion band photo" target="_blank" underline="hover" color={ theme.palette.link.main }>Bandphoto</Link>
            </Stack>
            <h3>Venue/Organizer downloads</h3>
            <Link href={ TechRider } download="Martyrion TechRider" target="_blank" underline="hover" color={ theme.palette.link.main }>Technical Rider</Link>
        </div>
     );
}
 
export default Downloads;