import {createTheme} from '@mui/material/styles';
import background from './data/background.png'
import './GaiaUprising.css'

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor) => augmentColor({color: {main: mainColor}});

export const gaiaUprisingTheme = createTheme({
    palette: {
        primary: {
            main: '#2e3944'
        },
        secondary: {
            main: '#72787e'
        },
        text: {
            primary: '#fff',
            secondary: 'rgba(255,255,255,0.2)'
        },
        background: {
            default: '#121212',
            paper: 'rgba(0,0,0,0)'
        },
        action: {
            disabledBackground: 'rgba(255, 255, 255, 0.2)'
        },
        button: createColor('#525D7A'),
        link: createColor('#fff')
    },
    typography: {
        fontFamily: "Verdana, Arial, sans-serif",
        body1: {
            fontSize: "0.875rem"
        }
    },
    pageBackgroundStyle: {
        background: `no-repeat url(${background}) right`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "stretch",
        backgroundPositionY: "100px"        
    }
});