import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

const IconLinks = [
    {name: "facebook", link: "https://facebook.com/MartyrionOfficial", icon: brands('facebook')},
    {name: "instagram", link: "https://instagram.com/Martyrion_Band", icon: brands('instagram')},
    {name: "spotify", link: "https://open.spotify.com/artist/2mDDiaKFruv2doyTarfN8J", icon: brands('spotify')},
    {name: "bandcamp", link: "https://martyrion.bandcamp.com", icon: brands('bandcamp')},
    {name: "youtube", link: "https://www.youtube.com/user/MARTYRIONofficial", icon: brands('youtube')}
]

export default IconLinks;