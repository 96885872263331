import {useEffect, useState} from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import useScript from "../hooks/useScript";

const Home = ({ setPageTitle }) => {

    useScript("https://apps.elfsight.com/p/platform.js", true);
    const [isDevelopment, setIsDevelopment] = useState(false);

    useEffect(() => 
    {
        setPageTitle("Home");
        
        if (process.env.REACT_APP_ENV === "DEVELOPMENT")
            setIsDevelopment(true);
        
    }, []);

    return ( 
        <div className="home">
            <h2>Home</h2>
            { isDevelopment && 
                <div>
                    <h3 className="development">This is a staging page</h3>
                    <p>To save on out limited access of the instagram addin, this will not be displayed here.</p>
                    <p>Instead have fun with this beautiful loading animation :-)</p>
                    <LoadingOverlay />
                </div>}
            { !isDevelopment && <div class="elfsight-app-135dfc8d-b5a8-4226-a36b-350b31be1383"></div> }
        </div>
     );
}
 
export default Home;