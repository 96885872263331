import { useTheme } from "@emotion/react";
import { Link } from "@mui/material";
import { useEffect } from "react";

const Disclaimer = ({ setPageTitle }) => {

    const theme = useTheme();

    useEffect(() => setPageTitle("Disclaimer"), []);

    return ( 
        <div>
            <h2>Disclaimer</h2>
            <h4>Last updated February 24, 2023</h4>
            <h3>Website Disclaimer</h3>
            <p>The information provided by Martyrion ("we," "us," or "our") on
               <Link href="https://www.martyrion.com" underline="hover" color={ theme.palette.link.main }> https://www.martyrion.com </Link>
               (the "Site") and our mobile application is for general informational purposes only.
               All information on the Site and our mobile application is provided in good faith, however
               we make no representation or warranty of any kind, express or implied,
               regarding the accuracy, adequacy, validity, reliability, availability,
               or completeness of any information on the Site or our mobile application.
               UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR
               DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR
               MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE
               AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE
               APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR
               MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p>
               
            <h3>Website Disclaimer</h3>
            <p>The Site and our mobile application may contain (or you may be sent through
                the Site or our mobile application) links to other websites or content belonging
                to or originating from third parties or links to websites and features in banners
                or other advertising. Such external links are not investigated, monitored, or
                checked for accuracy, adequacy, validity, reliability, availability, or
                completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
                THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN
                ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS
                OF PRODUCTS OR SERVICES.</p>
        </div>
     );
}
 
export default Disclaimer;