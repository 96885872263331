import LoadingOverlay from "../../../components/LoadingOverlay";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {gaiaUprisingTheme} from '../../../themes/gaia-uprising/GaiaUprisingTheme';
import Grid from "@mui/material/Grid2";
import {
    Backdrop,
    Button,
    ButtonGroup,
    IconButton,
    ImageList,
    ImageListItem,
    Link,
    Paper
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import YouTube from "react-youtube";
import bandLogo from "../../../themes/gaia-uprising/data/Logo.png"
import bandPhoto from "./data/images/band.jpg";
import bandPhoto2 from "./data/images/Martyrion schwarz.png";
import marian from "./data/images/marian.JPG";
import felix from "./data/images/felix.JPG";
import hendrik from "./data/images/hendrik.JPG";
import tobias from "./data/images/tobias.JPG";
import albumCover from "./data/images/AlbumCover.png"
import contentDe from "./data/GaiaUprisingDe.txt";
import contentEn from "./data/GaiaUprisingEn.txt";
import infoDe from "./data/InfoDe.txt";
import infoEn from "./data/InfoEn.txt";
import BandInfoDe from "./data/BandInfoDe.txt";
import BandInfoEn from "./data/BandInfoEn.txt";

const GaiaUprising = ({setPageTitle, setTheme, setContainerWidth, setNavbarVisible}) => {

    const title = "Gaia Uprising (2025) Promo";
    const [loading, setLoading] = useState(true);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [backdropImage, setBackdropImage] = useState(null);
    const [language, setLanguage] = useState('en');
    const [content, setContent] = useState('');
    const [albumInfo, setAlbumInfo] = useState('');
    const [bandInfo, setBandInfo] = useState('');

    const downloadLink = "https://drive.google.com/file/d/10NWQF_5_Bg2ra9loGYUaA8jT_haL_qrA/view?usp=sharing";

    const images = [bandPhoto, bandPhoto2, marian, felix, hendrik, tobias];

    useEffect(() => {
        const start = async () => {
            setNavbarVisible(false);
            setTheme(gaiaUprisingTheme);
            setContainerWidth('lg');
            setPageTitle(title);
            await selectLanguage('en');
            setLoading(false);
        }

        start().catch(console.error);
    }, []);

    const selectLanguage = async language => {
        setLanguage(language);
        let response = language === 'de'
            ? await fetch(contentDe)
            : await fetch(contentEn);

        let text = await response.text();
        text = text.replace(/\n+/g, "")
            .replace(/(\<\/h2\>)(\s)*/g, "$1")
            .replace(/\n/g, "\<br \/\>");
        setContent(text);

        response = language === 'de'
            ? await fetch(infoDe)
            : await fetch(infoEn);

        text = await response.text();
        text = text.replace(/\n/g, "\<br \/\>");
        setAlbumInfo(text);

        response = language === 'de'
            ? await fetch(BandInfoDe)
            : await fetch(BandInfoEn);

        text = await response.text();
        setBandInfo(text);
    };

    const showBackdrop = (image) => {
        setBackdropImage(image);
        setBackdropOpen(true);
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="gaia-uprising">
                {loading && <LoadingOverlay/>}
                {
                    !loading &&
                    <>
                        <Grid container spacing={2}>
                            <Grid size={{sm: 12, md: 8}}>
                                <center>
                                    <img src={bandLogo} style={{width: '50%', marginTop: '20px'}}/>
                                </center>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>Select Language</h2>
                                    <ButtonGroup variant="contained">
                                        <Button onClick={() => selectLanguage('en')} disabled={language === 'en'}>
                                            <img width="24" height="24"
                                                 src="https://img.icons8.com/fluency/48/great-britain-circular.png"
                                                 alt="great-britain-circular"/>
                                        </Button>
                                        <Button onClick={() => selectLanguage('de')} disabled={language === 'de'}>
                                            <img width="24" height="24"
                                                 src="https://img.icons8.com/fluency/48/germany-circular.png"
                                                 alt="germany-circular"/>
                                        </Button>
                                    </ButtonGroup>
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    <p dangerouslySetInnerHTML={{__html: content}}/>
                                </Paper>

                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>Videos</h2>
                                    <YouTube videoId="R5DbFX83RZc" opts={{width: "100%"}}/>
                                </Paper>

                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>{language === 'de' ? 'Bilder' : 'Images'}</h2>
                                    <ImageList variant="masonry" sx={{width: "100%"}} cols={2}>
                                        {
                                            images.map(image => (
                                                <ImageListItem key={image.name}>
                                                    <img src={image} alt={image.name} loading="lazy"
                                                         onClick={() => showBackdrop(image)}/>
                                                </ImageListItem>
                                            ))
                                        }
                                    </ImageList>
                                </Paper>
                            </Grid>

                            <Grid size={{xs: 12, md: 4}}>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>Info</h2>
                                    <img src={albumCover} style={{width: '100%'}}
                                         onClick={() => showBackdrop(albumCover)}/>
                                    <div dangerouslySetInnerHTML={{__html: albumInfo}}/>
                                    <br/>
                                    Homepage: <Link target={"_blank"} href={"https://www.martyrion.com"}
                                                    sx={{color: "#aaa"}}>www.martyrion.com</Link>
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>Line-up</h2>
                                    <div dangerouslySetInnerHTML={{__html: bandInfo}}/>
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>{language === 'de' ? 'Diskografie' : 'Discography'}</h2>
                                    <li>Gaia Uprising (2025)</li>
                                    <li>Our Dystopia (2016)</li>
                                    <li>Refugium: Exile (2011, Re-Record 2016)</li>
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>{language === 'de' ? 'Kontakt' : 'Contact'}</h2>
                                    Marian Freye<br/>
                                    Margaretastr. 14<br/>
                                    50827 Köln<br/>
                                    GERMANY<br/>
                                    <br/>
                                    E-Mail: <Link target={"_blank"} href={"mailto:kontakt@martyrion.com"}
                                                  sx={{color: "#aaa"}}>kontakt@martyrion.com</Link>
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    <h2>Promo-Download</h2>
                                    <center>
                                        <IconButton size="large" color="secondary" sx={{backgroundColor: "#fff"}}
                                                    href={downloadLink} target={"_blank"}>
                                            <FileDownloadIcon/>
                                        </IconButton>
                                    </center>
                                    <br/>

                                    {language === 'de' && (
                                        <div>
                                            Über das Icon können Sie das komplette Promomaterial inklusive der
                                            MP3-Version
                                            des Albums herunterladen.<br/>
                                            <br/>
                                            Bitte beachten Sie, dass alle MP3s mit einem Wasserzeichen markiert sind.
                                            Illegale Vervielfältigungen des zur Verfügung gestellten Materials werden
                                            rechtlich verfolgt.
                                        </div>
                                    )}
                                    {language === 'en' && (
                                        <div>
                                            By clicking the icon above, you can download the full presskit including the
                                            MP3-Version of the album.<br/>
                                            <br/>
                                            Please note that the material is watermarked. Any illegal distribution of
                                            the
                                            material will be pursued.
                                        </div>
                                    )}
                                </Paper>
                                <Paper className={"paper-content"} elevation={4}>
                                    {language === 'de' &&
                                        (
                                            <>
                                                <h2>Anfragen & Interviews</h2>
                                                <div>
                                                    Wenn Sie an einem Interview, einem Feature oder einer anderen
                                                    Kooperation interessiert sind, kontaktieren Sie gerne Marian Freye
                                                    (kontakt@martyrion.com). <br/>
                                                    <br/>
                                                    Wir freuen uns auf eine gemeinsame Zusammenarbeit!
                                                </div>
                                            </>
                                        )
                                    }
                                    {language === 'en' &&
                                        (
                                            <>
                                                <h2>Requests & Interviews</h2>
                                                <div>
                                                    If you are interested in an interview, a feature or any other
                                                    cooperation please contact Marian Freye
                                                    (kontakt@martyrion.com). <br/>
                                                    <br/>
                                                    We'd be glad to work with you on any of your ideas!
                                                </div>
                                            </>
                                        )
                                    }
                                </Paper>
                            </Grid>
                        </Grid>

                        <Backdrop open={backdropOpen} onClick={() => {
                            setBackdropOpen(false)
                        }}>
                            <img src={backdropImage} style={{maxWidth: "90%", maxHeight: "90%", marginTop: "100px"}}/>
                        </Backdrop>
                    </>
                }
            </div>
        </>
    )

}

export default GaiaUprising;