import nuclearLogo from "../data/images/refugium_nukular.png"

const LoadingOverlay = () => {
    return ( 
        <div className="loading">
            <img src={ nuclearLogo } className="rotating-image" />
            <h1 className="elementToFadeInAndOut loading-text">Loading...</h1>
        </div>
     );
}
 
export default LoadingOverlay;