import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import pad from "../functions/pad";
import chapterInfo from "../data/chapters.json"
import { Button, Stack } from "@mui/material";

const ReChapter = ({ setPageTitle }) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [chapterText, setChapterText] = useState("");
    const [header, setHeader] = useState("");
    const [loading, setLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrevious, setHasPrevious] = useState(false);
    
    useEffect(() => {
        
        const fetchData = async () => {
            setLoading(true);
            setHasNext(false);
            setHasPrevious(false);

            const chapterFileName = pad(id, 3) + ".txt";

            const result = await fetch(`/re-story/${ chapterFileName }`);
            const chapterText = await result.text();
            
            const lines = chapterText.split("\n");
            setHeader(lines[0]);

            lines.splice(0, 2);
            setChapterText(lines.join("\n"));

            document.getElementById("main-container").scrollTop = 0;

            setLoading(false);
            setHasPrevious(id > 1);
            setHasNext(id < chapterInfo.Chapters.length)
        }

        setPageTitle("R:E-Story Chapter " + id);
        fetchData().catch(console.error);
    }, [id])

    const switchChapter = (forward) => 
    {
        let chapter = parseInt(id);
        chapter = forward ? chapter + 1 : chapter - 1;

        navigate("/re/" + chapter);
    }

    return (
        <>
        {
            loading
            ?
            <LoadingOverlay />
            :
            <>
                <h2>{ header }</h2>
                <p>{ chapterText }</p>
                <Stack direction="row" justifyContent="space-between" mt="64px">
                    <Button onClick={ () => switchChapter(false) } disabled={ !hasPrevious } color="button" variant="contained">
                        Previous
                    </Button>
                    <Button onClick={ () => switchChapter(true) } disabled={ !hasNext } color="button" variant="contained">
                        Next
                    </Button>
                </Stack>
            </>
        }
        </>
    );
}
 
export default ReChapter;