import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import chapterInfo from '../data/chapters.json'

async function getPageLinks() {
    return [
            {name: "Home", link: "/", icon: solid('home')},
            {name: "Band", link: "/band", icon: solid('people-group')},
            // {name: "Tour", link: "/", icon: solid("calendar-days")},
            {
                name: "Refugium: Exile", 
                sub: "Story",
                link: "/",
                icon: solid("radiation"),
                children: chapterInfo.Chapters.map((chapter, index) => {
                    return {
                        name: `Chapter ${ index + 1 }`,
                        link: `/re/${ index + 1 }`,
                        icon: solid("bookmark")
                    }
                })
            },
            {
                name: "Media",
                link: "/",
                icon: solid("photo-film"),
                children: 
                [
                    { name: "Music", link: "/music", icon: solid("compact-disc") },
                    { name: "Downloads", link: "/downloads", icon: solid("newspaper") }
                ]
            }            
        ];
    } 

export default getPageLinks;